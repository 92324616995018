import React from 'react';
import styled from 'styled-components';

import SEO from '../components/seo';
import Posts from '../components/sections/Posts';

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;
    return (
      <PostContainer>
        <SEO title="Posts" keywords={[`blog`, `javascript`, `react`]} />
        <Posts posts={posts} />
      </PostContainer>
    );
  }
}

const PostContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -56px;
  padding-bottom: 40px;
  border-bottom: 2px solid white;
`;

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
