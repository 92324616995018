import React from 'react';
import { Link } from 'gatsby';
import styled, { keyframes } from 'styled-components';

const Posts = ({ posts }) => {
  return (
    <PostsContainer>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        const date =
          node.frontmatter.date && node.frontmatter.date.toUpperCase();
        return (
          <Post key={node.fields.slug}>
            <p>{date}</p>
            <div>
              <Link to={`posts` + node.fields.slug.replace(/\/$/, ``)}>
                {title}
              </Link>
              <p
                style={{ marginTop: 8, opacity: 1 }}
              >
                {node.frontmatter.description}
              </p>
            </div>
          </Post>
        );
      })}
    </PostsContainer>
  );
};

const PostsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
  margin-right: -24px;
`;

const boop = keyframes`
  0% {
    transform: translateX(-5px);
  }
  33% {
    transform: translateX(0px);
  }
  66% {
    transform: translateX(-5px);
  }
  78% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-5px);
  }
`;

const Post = styled.div`
  width: 33%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 666px) {
    width: 50%;
  }
  a {
    ::after {
      transition: opacity 300ms ease-in;
      opacity: 0;
      position: absolute;
      margin-left: 8px;
      content: '→';
    }
    &:hover {
      ::after {
        opacity: 1;
        animation: ${boop} 1000ms ease infinite;
      }
    }
  }
  && p {
    opacity: 0.66;
    font-size: 9px;
    opacity: 0.66;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;

export default Posts;
